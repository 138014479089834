<template>
    <div></div>
</template>
<script>
import {wechat} from "@/api/user";
export default {
    name:'LoginWechat',
    data() {
        return {
            loading: true,
            code:this.$route.query.code,
        }
    },
    created() {
        let _this = this;
        if (!this.code) {
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa44445e62d5493b8&redirect_uri='+(encodeURIComponent('https://satisfaction.thape.com.cn/login/wechat'))+'&response_type=code&scope=snsapi_userinfo&agentid=1000162&state=STATE#wechat_redirect';
        } else {
            _this.wechatLogin();
        }
    },
    methods: {
        async wechatLogin() {
            this.$toast.loading({
              message: '登录中...',
              forbidClick: true,
            });
            let _this = this;
            const res = await wechat({code:_this.code});
            if (res.code == 1) {
                localStorage.setItem('token', res.data.token);
                this.$toast.setDefaultOptions({ duration: 1000 });
                this.$toast.success(res.msg);
                setTimeout(ress=> {
                    this.$router.push({
                        path:'/'
                    })
                }, 1500); 
            }
        }
    },
}    
</script>
